:root {
  --color: #ffffff;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #282c34;
  background-image: url("./static/images/background_pattern.jpg");
  background-size: 20em;
  color: var(--color);
  font-size: calc(9px + 1vmin);
}

.App-link {
  color: #61dafb;
}

.sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

.container {
  margin-right: auto;
  margin-left: auto;
  max-width: 95ch;
}

.bg-white {
  background-color: #ffffffdd;
  --color: #000000;
}

.capitalize {
  text-transform: capitalize;
}

.text-base {
  color: var(--color);
}

.label {
  color: var(--color);
}

.padding {
  padding: calc(1em + 1vmin);
}

.margin-vertical {
  margin-top: calc(1rem + 2vmin);
  margin-bottom: calc(1rem + 2vmin);
}

.margin-gutter {
  margin: calc(1rem + 2vmin);
}

.button {
  padding: calc(.25em + 1vmin) calc(1em + 1vmin);
  width: 100%;
  font-size: .75em;
  background-color: black;
  color: white;
  border: .15em solid black;
  appearance: none;
  cursor: pointer;
}

.button:hover {
  text-transform: underline;
}

.button:active {
  border-color: #53b021;
}

.button.active {
  border-color: #53b021;
  background-color: #53b021;
  color: black;
}

.radioLabel {
  display: inline-block;
  padding: calc(.25em + 1vmin) calc(1em + 1vmin);
  font-size: .75em;
  background-color: transparent;
  color: black;
  appearance: none;
  cursor: pointer;
  margin-right: .5rem;
  margin-bottom: .5rem;
  border: .15em solid black;
  background-color: #ffffff;
}

.radioInput:checked + .radioLabel {
  color: black;
  background-color: #53b021;
}

fieldset {
  border: .15em solid black;
}

fieldset:focus-within {
  background-color: #53b02133;
}

fieldset:active {
  background-color: #53b02133;
}

.output-wrapper {
  padding: .5em;
  background-color: #ffffff99;
}

.textarea {
  display: block;
  width: 100%; 
  border: none;
  box-shadow: none;
  font-size: 1.05em; 
  line-height: 1.5;
  background-color: transparent;
}
